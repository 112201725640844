import { ButtonType } from './types';
import './styles/Button.css';

const Button: ButtonType = ({
  children,
  onClick,
  pattern = 'default',
  size = 'large',
  width,
  className,
  styles,
  disabled = false,
}) => {
  return (
    <button
      className={`button ${className}`}
      data-pattern={pattern}
      data-size={size}
      onClick={onClick}
      style={{ width, minWidth: width, ...styles }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
