import './styles.css';
import info from '../../assets/info.svg';
import curv from '../../assets/curv2.svg';
import Button from '../../ui-kit/Button';

import level1 from '../../assets/card/1.mp4';
import level2 from '../../assets/card/2.mp4';
import level3 from '../../assets/card/3.mp4';
import level4 from '../../assets/card/4.mp4';
import level5 from '../../assets/card/5.mp4';
import level6 from '../../assets/card/6.mp4';
import level7 from '../../assets/card/7.mp4';
import level8 from '../../assets/card/8.mp4';
import level9 from '../../assets/card/9.mp4';
import level10 from '../../assets/card/10.mp4';

import { Data } from '../../types';

const Card = ({
  data,
  onOpenInfo,
}: {
  data: Data | null;
  onOpenInfo: () => void;
}) => {
  const getLevelVideo = (level: number) => {
    switch (level) {
      case 1:
        return level1;
      case 2:
        return level2;
      case 3:
        return level3;
      case 4:
        return level4;
      case 5:
        return level5;
      case 6:
        return level6;
      case 7:
        return level7;
      case 8:
        return level8;
      case 9:
        return level9;
      case 10:
        return level10;
      default:
        return level1;
    }
  };

  return (
    <div className="card_wrapper">
      <div className="card_container">
        <div className="card_video_background">
          <video autoPlay muted loop playsInline>
            <source
              src={getLevelVideo(data?.Level?.Level || 1)}
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="card_info">
          <Button size="small" pattern="secondary" onClick={onOpenInfo}>
            <img src={info} height={20} width={20} />
          </Button>
        </div>
        <div className="card_status_container">
          <div className="card_status_information">
            <div className="card_status_information_subtitle">
              {data?.Level.LevelName}
            </div>
          </div>
          <div className="card_status_level">
            <img className="curv" src={curv} />
            <div className="card_status_level_number">
              {data !== null && data?.Level?.Level < 10
                ? `0${data?.Level?.Level}`
                : 10}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
// <div style={{ width: '100%', position: 'absolute', bottom: '0px' }}>
//   <ProgressBar currentValue={10} maxValue={20} />
// </div>;
