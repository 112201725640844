import { Point } from '../components/PointList/types';

export const roadmapList1: Array<Point> = [
  {
    id: '1',
    title: 'Alfa version - we are here',
    isActive: true,
  },
  {
    id: '2',
    title: 'Main app launch',
  },
  {
    id: '3',
    title: '1st Mining epoch',
  },
];
