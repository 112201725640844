import { Mode } from '../types';

export const useMode = (): { mode: Mode } => {
  // const isProd = window.location.hostname === 'tma-mining-game.emcd.io';
  // const isStage =
  //   window.location.hostname === 'tma-mining-game.stage.mytstnv.site';
  const isDev = window.location.hostname === 'localhost';

  const mode: Mode = isDev ? 'DEV' : 'PROD';
  return { mode };
};
