import arrow from '../../assets/arrow_new.svg';
import location_large from '../../assets/location_large.png';
import styles from './styles.module.scss';

const list1 = [
  {
    key: 1,
    title: 'Alfa version App start',
    isActive: true,
  },
  {
    key: 2,
    title: 'Community building',
    isActive: false,
  },
  {
    key: 3,
    title: 'Main app start',
    isActive: false,
  },
  {
    key: 4,
    title: 'Mini epoch 1',
    isActive: false,
  },
];

const list2 = [
  {
    key: 1,
    title: 'Boosters and tasks',
    isActive: false,
  },
  {
    key: 2,
    title: 'EMCD Wallet',
    isActive: false,
  },
  {
    key: 3,
    title: 'Token utility building',
    isActive: false,
  },
  {
    key: 4,
    title: 'AirDrop preparation',
    isActive: false,
  },
  {
    key: 5,
    title: 'TGE',
    isActive: false,
  },
  {
    key: 6,
    title: 'AirDrop',
    isActive: false,
  },
  {
    key: 7,
    title: 'Listing',
    isActive: false,
  },
];

const NewRoadmap = ({ onClose }: { onClose(): void }) => {
  return (
    <div className={styles.container}>
      <div className={styles.close_button_container}>
        <button className={styles.close_button} onClick={onClose}>
          <img src={arrow} onClick={onClose} />
        </button>
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.header_left}>
            <div className={`${styles.header_title} ${styles.heading1}`}>
              Roadmap
            </div>
            <div className={`${styles.header_subtitle} ${styles.heading1}`}>
              Q3
            </div>
          </div>
          <div className={styles.header_right}>
            <img src={location_large} className={styles.header_image} />
          </div>
        </div>
        <div className={styles.q3_list_container}>
          {list1.map((item) => (
            <div key={item.key} className={styles.q3_list_item}>
              <div
                data-active={item.isActive}
                className={styles.q3_list_item_circle}
              />
              <div
                data-active={item.isActive}
                className={styles.q3_list_item_title}
              >
                {item.title}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.upcoming_list_container}>
          <div className={styles.heading1}>Upcoming</div>
          <div className={styles.upcoming_list_container_content}>
            {list2.map((item) => (
              <div key={item.key} className={styles.q3_list_item}>
                <div
                  data-active={item.isActive}
                  className={styles.q3_list_item_circle}
                />
                <div
                  data-active={item.isActive}
                  className={styles.q3_list_item_title}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.description}>
          Roadmap is a changing substance. We’re doing out best to bring for
          best community ever. Stay tuned for more news and updates.
        </div>
      </div>
    </div>
  );
};

export default NewRoadmap;
