import { FC, useEffect, useState } from 'react';
import energy from '../../assets/energy.svg';
import styles from './styles.module.scss';

interface EnergyButtonProps {
  currentValue: number;
  isCharging?: boolean;
  timeToStart?: string;
  timeToEnd?: string;
  onOpenCharging?: () => void;
  isChargeCompleted?: boolean;
}
export const EnergyButton: FC<EnergyButtonProps> = ({
  currentValue,
  isCharging,
  timeToStart,
  timeToEnd,
  onOpenCharging,
  isChargeCompleted,
}) => {
  const [progressWidth, setProgressWidth] = useState(0);
  const [remainingTime, setRemainingTime] = useState('');

  const calculateRemainingTime = (end: Date) => {
    const now = new Date();
    const diffInMs = end.getTime() - now.getTime();
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    if (isCharging && timeToStart && timeToEnd) {
      const start = new Date(timeToStart);
      const end = new Date(timeToEnd);
      const totalDuration = end.getTime() - start.getTime();

      const interval = setInterval(() => {
        const now = new Date();
        const elapsedTime = now.getTime() - start.getTime();
        const progress = (elapsedTime / totalDuration) * 100;
        setProgressWidth(Math.min(progress, 100));

        setRemainingTime(calculateRemainingTime(end));
      }, 1); // Обновляем каждую миллисекунду

      setProgressWidth(
        ((new Date().getTime() - start.getTime()) / totalDuration) * 100
      );
      setRemainingTime(calculateRemainingTime(end));

      return () => clearInterval(interval);
    }
  }, [isCharging, timeToStart, timeToEnd]);

  return (
    <div className={styles.wrapper}>
      <button className={styles.container} onClick={onOpenCharging}>
        <div
          className={styles.progress}
          style={{ width: isCharging ? `${progressWidth}%` : '100%' }}
        />
        <div className={styles.content}>
          <div className={styles.title}>
            Store <br /> Energy
          </div>
          <div className={styles.right}>
            <div className={styles.value_row}>
              <img src={energy} />
              <div className={styles.current}>
                {currentValue.toLocaleString('ru-RU')}
              </div>
            </div>
            {(isCharging || isChargeCompleted) && (
              <div className={styles.charging}>
                {isCharging && (
                  <div className={styles.charging_time}>{remainingTime}</div>
                )}
                <div
                  className={styles.charging_text}
                  style={{
                    opacity: isChargeCompleted ? 1 : 0.5,
                  }}
                >
                  {isCharging ? 'Charging...' : 'Charge completed'}
                </div>
              </div>
            )}
          </div>
        </div>
      </button>
    </div>
  );
};
