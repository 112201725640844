import { useState } from 'react';
import './styles/Error.css';
import Button from '../../ui-kit/Button';
import { ErrorType } from './types';
import cloud from '../../assets/3d_cloud.png';

const Error: ErrorType = ({ isOpen, onReload }) => {
  const [shouldRender, setShouldRender] = useState(isOpen);

  return (
    <div
      className="error_wrapper"
      data-is-open={isOpen}
      onTransitionEnd={() => setShouldRender(isOpen)}
      style={{ zIndex: shouldRender ? 4 : -1 }}
    >
      <div className="error_container">
        <img className="error_image" src={cloud} />
        <h2 className="error_title">Something went wrong</h2>
        <p className="error_subtitle">Lets try to reload the page</p>
      </div>
      <div className="error_footer">
        <Button onClick={onReload} pattern="error">
          Reload
        </Button>
      </div>
    </div>
  );
};

export default Error;
