import { FC } from 'react';
import styles from './styles.module.scss';
import { Data } from '../../types';
import Button from '../../ui-kit/Button';
import share from '../../assets/share.svg';
import person from '../../assets/person.svg';
interface NewProgressBarProps {
  data: Data | null;
  currentValue: number;
  maxValue: number;
  handleInvite: () => void;
  handleShowNotification: () => void;
}
export const NewProgressBar: FC<NewProgressBarProps> = ({
  currentValue,
  maxValue,
  data,
  handleShowNotification,
  handleInvite,
}) => {
  const percentage = (currentValue / maxValue) * 100;
  const handleCopyClick = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(data?.RefUrl || '');
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = data?.RefUrl || '';
      textArea.style.position = 'fixed';
      textArea.style.opacity = '0';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Текст скопирован!');
      } catch (err) {
        console.error('Не удалось скопировать текст', err);
      }
      document.body.removeChild(textArea);
    }
    handleShowNotification();
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container} onClick={handleInvite}>
        <div className={styles.progress} style={{ width: `${percentage}%` }} />
        <div className={styles.content}>
          <div className={styles.title}>
            Invite <br /> Friends
          </div>
          <div className={styles.right}>
            <div className={styles.count}>
              <img
                src={person}
                style={{
                  marginRight: '6px',
                }}
              />
              <div className={styles.current_count}>{currentValue}</div>
              <div className={styles.full_count}>/{maxValue}</div>
            </div>
            <div className={styles.levels_row}>
              <div className={styles.level}>
                <div className={styles.level_count}>
                  {data?.Friends?.FirstLevelFriends}
                </div>
                <div className={styles.level_name}>1st lvl</div>
              </div>
              <div className={styles.level}>
                <div className={styles.level_count}>
                  {data?.Friends?.SecondLevelFriends}
                </div>
                <div className={styles.level_name}>2nd lvl</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        pattern="primary"
        onClick={handleCopyClick}
        className={styles.copy_button}
      >
        <img src={share} />
      </Button>
    </div>
  );
};
