import ball from '../assets/carousel/ball.png';
import team from '../assets/carousel/team.png';
import location from '../assets/carousel/location.png';
import atom from '../assets/carousel/atom.png';
import { Text } from '../ui-kit/Text/Text';
import { PointList } from '../components/PointList/PointList';
import { roadmapList1 } from './roadmap';
import { CSSProperties } from 'react';

interface CarouselItem {
  id: string;
  image: string;
  imageStyles?: CSSProperties;
  title: JSX.Element;
  subtitle: JSX.Element;
  subtitleStyles?: CSSProperties;
}

export const carouselItems: Array<CarouselItem> = [
  {
    id: '1',
    image: ball,
    imageStyles: {
      width: '100%',
    },
    subtitleStyles: { maxWidth: '158px' },
    title: <Text as="h2">Welcome to EMCD</Text>,
    subtitle: (
      <Text>You're at the start of something great. Among the first.</Text>
    ),
  },
  {
    id: '2',
    image: team,
    imageStyles: { width: '66%' },
    subtitleStyles: { maxWidth: '205px' },
    title: <Text as="h2">Become a leader!</Text>,
    subtitle: (
      <Text>Invite your friends and get the early follower bonus.</Text>
    ),
  },
  {
    id: '3',
    image: location,
    imageStyles: { width: '56%' },
    title: <Text as="h2">Q3 Roadmap</Text>,
    subtitle: <PointList items={roadmapList1} />,
  },
  {
    id: '4',
    image: atom,
    imageStyles: { width: '59%' },
    subtitleStyles: { maxWidth: '158px' },
    title: <Text as="h2">&nbsp;</Text>,
    subtitle: (
      <Text>Join the community and stay up to date with all the changes.</Text>
    ),
  },
];
