import './Draver.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
const Drawer = ({ isOpen, onClose, children }: Props) => {
  return (
    <div className={`drawer-container ${isOpen ? 'open' : ''}`}>
      <div className="drawer">{children}</div>
      <div className="overlay" onClick={onClose} data-is-open={isOpen}></div>
    </div>
  );
};

export default Drawer;
