import './styles/Loading.css';
import logo from '../../assets/logo_large.svg';
import { LoadingPageType } from './types';
import { useState } from 'react';
// import { Text } from '../../ui-kit/Text/Text';

const Loading: LoadingPageType = ({ isOpen }) => {
  const [shouldRender, setShouldRender] = useState(isOpen);

  const onTransitionEnd = () => {
    if (!isOpen) {
      setShouldRender(false);
    }
  };

  if (!shouldRender) {
    return <></>;
  }

  return (
    <div
      className="loading_container"
      data-is-open={isOpen}
      onTransitionEnd={onTransitionEnd}
      style={{ zIndex: shouldRender ? 10 : -1 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '14px',
        }}
      >
        <img src={logo} />
        {/* <Text>Release version 1.0.8</Text> */}
      </div>
    </div>
  );
};

export default Loading;
