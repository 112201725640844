import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import axios from 'axios';

Sentry.init({
  dsn: 'https://2c8ae866b30f05f46c43e9825da3cbd6@o4508137746071552.ingest.de.sentry.io/4508137747972176',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.contextLinesIntegration({ frameContextLines: 10 }),
    Sentry.httpClientIntegration(),
    Sentry.breadcrumbsIntegration({
      console: false,
      dom: false,
      history: false,
      fetch: true,
      xhr: true,
    }),
    Sentry.browserApiErrorsIntegration({
      setTimeout: true,
      setInterval: true,
      requestAnimationFrame: true,
      XMLHttpRequest: true,
      eventTarget: true,
    }),
    Sentry.httpClientIntegration(),
  ],

  tracesSampleRate: 1.0,
  sendDefaultPii: true,
  tracePropagationTargets: [/^https:\/\/web-app\.emcd\.io\/emcd/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  maxValueLength: 500,
  normalizeDepth: 5,
  normalizeMaxBreadth: 1000,
  ignoreErrors: ['ResizeObserver loop limit exceeded', /Network Error/],
  denyUrls: [/extensions\//i, /^chrome:\/\//i],

  initialScope: (scope) => {
    return scope;
  },
  beforeSend(event) {
    if (event.request) {
      if (event.request.data) {
        event.request.data = JSON.stringify(event.request.data);
      }
    }

    if (event.contexts && event.contexts.response) {
      if (event.contexts.response.body) {
        event.contexts.response.body = JSON.stringify(
          event.contexts.response.body
        );
      }
    }

    return event;
  },
});

axios.interceptors.request.use((config) => {
  const { method = '', url, headers, data } = config;

  Sentry.addBreadcrumb({
    category: 'http',
    message: `Request: ${method.toUpperCase()} ${url}`,
    data: { headers, data },
    level: 'info',
  });

  return config;
});

axios.interceptors.response.use(
  (response) => {
    const {
      config: { url = '' },
      status,
      headers,
      data,
    } = response;

    Sentry.addBreadcrumb({
      category: 'http',
      message: `Response: ${status} ${url}`,
      data: { headers, data },
      level: 'info',
    });

    return response;
  },
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
