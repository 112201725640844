export const levels = [
  {
    title: 'Beginner',
    friends: 0,
  },
  {
    title: 'Advanced',
    friends: 1,
  },
  {
    title: 'Turbo',
    friends: 5,
  },
  {
    title: 'Tiger Power',
    friends: 10,
  },
  {
    title: 'Monster',
    friends: 100,
  },
  {
    title: 'Beast',
    friends: 500,
  },
  {
    title: 'Supercomputer',
    friends: 1000,
  },
  {
    title: 'Quantum Monster',
    friends: 5000,
  },
  {
    title: 'Galactic Processor',
    friends: 10000,
  },
];
