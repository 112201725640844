import { PointListType } from './types';
import styles from './PointList.module.scss';

export const PointList: PointListType = ({ items }) => (
  <div className={styles.container}>
    {items.map(({ id, isActive, title }) => (
      <div key={id} className={styles.row} data-active={isActive}>
        <div className={styles.point} />
        <div className={styles.title}>{title}</div>
      </div>
    ))}
  </div>
);
