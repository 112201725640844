import React, { useEffect, useState } from 'react';
import styles from './Notification.module.scss';
import success from '../../assets/success.svg';
interface NotificationProps {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  duration: number;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  message,
  type,
  duration,
  onClose,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, duration);
  };

  const getIcon = (type: 'success' | 'error' | 'info' | 'warning') => {
    switch (type) {
      case 'success':
        return success;
      case 'error':
        return '';
      case 'info':
        return '';
      case 'warning':
        return '';
      default:
        return success;
    }
  };

  return (
    <div
      className={`${styles.notification} ${styles[type]} ${
        visible ? styles.show : styles.hide
      }`}
    >
      <img src={getIcon(type)} height={20} width={20} />
      <div className={styles.message}>{message}</div>
      <button className={styles.closeButton} onClick={handleClose}>
        &times;
      </button>
    </div>
  );
};

export default Notification;
