import { TextType } from './types';
import styles from './Text.module.scss';

export const Text: TextType = ({ children, className, as = 'p', ...props }) => {
  const Tag = as;

  return (
    <Tag className={`${styles[`ui-text-${as}`]} ${className} `} {...props}>
      {children}
    </Tag>
  );
};
