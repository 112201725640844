import { useState, useRef } from 'react';
import Button from '../../ui-kit/Button';
import { CarouselType } from './types';
import styles from './Carousel.module.scss';
import { carouselItems } from '../../data/carousel';

const Carousel: CarouselType = ({ isOpen, onCancel }) => {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const [prevTranslate, setPrevTranslate] = useState(0);
  const carouselSlidesRef = useRef<HTMLDivElement>(null); // Обновляем реф для слайдов

  const nextSlide = () => {
    if (currentSlide === carouselItems.length - 1) {
      onCancel();
      return;
    }
    setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + carouselItems.length) % carouselItems.length
    );
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    setIsDragging(true);
    const touch = event.touches[0];
    setStartPos(touch.clientX);
    setPrevTranslate(currentTranslate);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (!isDragging) return;
    const touch = event.touches[0];
    const delta = touch.clientX - startPos;
    setCurrentTranslate(prevTranslate + delta);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    const moveBy = currentTranslate - prevTranslate;

    if (moveBy < -50) {
      nextSlide();
    } else if (moveBy > 50 && currentSlide > 0) {
      prevSlide();
    }

    setCurrentTranslate(0);
    setPrevTranslate(0);
  };

  const handleMouseStart = (event: React.MouseEvent) => {
    setIsDragging(true);
    setStartPos(event.clientX);
    setPrevTranslate(currentTranslate);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isDragging) return;
    const delta = event.clientX - startPos;
    setCurrentTranslate(prevTranslate + delta);
  };

  const handleMouseEnd = () => {
    setIsDragging(false);
    const moveBy = currentTranslate - prevTranslate;
    if (moveBy < -50) {
      nextSlide();
    } else if (moveBy > 50 && currentSlide > 0) {
      prevSlide();
    }

    setCurrentTranslate(0);
    setPrevTranslate(0);
  };

  return (
    <div
      className={styles.carousel_wrapper}
      data-is-open={isOpen}
      onTransitionEnd={() => setShouldRender(isOpen)}
      style={{ zIndex: shouldRender ? 3 : -1 }}
    >
      <div
        className={styles.carousel_container}
        onMouseDown={handleMouseStart}
        onTouchStart={handleTouchStart}
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        onMouseUp={handleMouseEnd}
        onTouchEnd={handleTouchEnd}
        onMouseLeave={handleMouseEnd}
      >
        <div
          className={styles.carousel_slides}
          ref={carouselSlidesRef} // Применяем к слайдам
          style={{
            transform: `translateX(calc(-${
              currentSlide * 100
            }% + ${currentTranslate}px))`,
            transition: isDragging ? 'none' : 'transform 0.5s ease-in-out',
          }}
        >
          {carouselItems.map(({ id, image, title, subtitle, imageStyles }) => (
            <div key={id} className={styles.carousel_slide}>
              <div className={styles.content}>
                <div className={styles.image}>
                  <img
                    src={image}
                    alt={''}
                    className={styles.carousel_slide_image}
                    style={imageStyles}
                  />
                </div>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.carousel_footer}>
        <div className={styles.carousel_navigation}>
          {carouselItems.map(({ id }, index) => (
            <div
              key={`dot-${id}`}
              className={`${styles.dot} ${
                index === currentSlide && styles.dot_active
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
        <Button onClick={onCancel}>Skip</Button>
      </div>
    </div>
  );
};

export default Carousel;
