import React, { useEffect, useState, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import step1 from '../../../assets/animation/Step1.json';
import step2 from '../../../assets/animation/Step2.json';
import step3 from '../../../assets/animation/Step3.json';
import step4 from '../../../assets/animation/Step4.json';

interface AnimationProps {
  isCharging: boolean;
  isChargeCompleted: boolean;
  changeBatteryClicked: boolean;
  onBatteryChangeComplete: () => void;
  justStartedCharging: boolean;
  resetJustStartedCharging: () => void;
}

enum AnimationState {
  Step1 = 'Step1',
  Step2 = 'Step2',
  Step3 = 'Step3',
  Step4 = 'Step4',
}

export const Animation: React.FC<AnimationProps> = ({
  isCharging,
  isChargeCompleted,
  changeBatteryClicked,
  justStartedCharging,
  resetJustStartedCharging,
}) => {
  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.Step1
  );
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const [loop, setLoop] = useState(true);
  const [currentAnimationData, setCurrentAnimationData] = useState(step1);

  // Обновляем анимацию на основе состояния
  useEffect(() => {
    switch (animationState) {
      case AnimationState.Step1:
        setCurrentAnimationData(step1);
        setLoop(true);
        break;
      case AnimationState.Step2:
        setCurrentAnimationData(step2);
        setLoop(false);
        break;
      case AnimationState.Step3:
        setCurrentAnimationData(step3);
        setLoop(true);
        break;
      case AnimationState.Step4:
        setCurrentAnimationData(step4);
        setLoop(false);
        break;
    }
  }, [animationState]);

  // Проигрываем половину анимации для step2 и step4
  useEffect(() => {
    if (
      animationState === AnimationState.Step2 ||
      animationState === AnimationState.Step4
    ) {
      if (lottieRef.current) {
        const totalFrames = lottieRef.current.getDuration(true) ?? 0;
        const halfFrames = totalFrames / 2;
        lottieRef.current.playSegments([0, halfFrames], true);
      }
    }
  }, [animationState]);

  // Обрабатываем переключение анимаций
  useEffect(() => {
    if (changeBatteryClicked) {
      setAnimationState(AnimationState.Step4);
    } else if (isCharging) {
      if (justStartedCharging) {
        setAnimationState(AnimationState.Step2);
      } else {
        setAnimationState(AnimationState.Step3);
      }
    } else if (isChargeCompleted) {
      setAnimationState(AnimationState.Step3);
    } else {
      setAnimationState(AnimationState.Step1);
    }
  }, [
    isCharging,
    isChargeCompleted,
    justStartedCharging,
    changeBatteryClicked,
  ]);

  const handleAnimationComplete = () => {
    if (animationState === AnimationState.Step2) {
      setAnimationState(AnimationState.Step3);
      resetJustStartedCharging();
    } else if (animationState === AnimationState.Step4) {
      setAnimationState(AnimationState.Step3);
    }
  };

  return (
    <Lottie
      animationData={currentAnimationData}
      loop={loop}
      onComplete={handleAnimationComplete}
      lottieRef={lottieRef}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
