export const myid = 401069859;
export const mockWebApp = {
  initData:
    'query_id=AAEj1-cXAAAAACPX5xfqIKui&user=%7B%22id%22%3A401069859%2C%22first_name%22%3A%22%D0%9C%D0%B8%D1%85%D0%B0%D0%B8%D0%BB%22%2C%22last_name%22%3A%22%D0%A1%D0%B0%D0%B2%D0%B5%D0%BB%D1%8C%D0%B5%D0%B2%22%2C%22username%22%3A%22savelev_m%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1725004025&hash=bc49de059b728a5f0546ac3e2456521d809a35d2b8bd6d1258529c8c068e236b',
  initDataUnsafe: {
    query_id: 'AAEj1-cXAAAAACPX5xfqIKui',
    user: {
      id: 401069859,
      first_name: 'Михаил',
      last_name: 'Савельев',
      username: 'savelev_m',
      language_code: 'ru',
      is_premium: true,
      allows_write_to_pm: true,
    },
    auth_date: '1725004025',
    hash: 'bc49de059b728a5f0546ac3e2456521d809a35d2b8bd6d1258529c8c068e236b',
  },
  version: '7.8',
  platform: 'macos',
  colorScheme: 'dark',
  themeParams: {
    subtitle_text_color: '#b1c3d5',
    bg_color: '#18222d',
    accent_text_color: '#2ea6ff',
    link_color: '#62bcf9',
    hint_color: '#b1c3d5',
    button_text_color: '#ffffff',
    secondary_bg_color: '#131415',
    header_bg_color: '#131415',
    destructive_text_color: '#ef5b5b',
    section_bg_color: '#18222d',
    text_color: '#ffffff',
    section_header_text_color: '#b1c3d5',
    button_color: '#2ea6ff',
  },
  isExpanded: true,
  viewportHeight: 645,
  viewportStableHeight: 645,
  isClosingConfirmationEnabled: false,
  isVerticalSwipesEnabled: true,
  headerColor: '#18222d',
  backgroundColor: '#18222d',
  BackButton: { isVisible: false },
  MainButton: {
    text: 'CONTINUE',
    color: '#2ea6ff',
    textColor: '#ffffff',
    isVisible: false,
    isProgressVisible: false,
    isActive: true,
  },
  SettingsButton: { isVisible: false },
  HapticFeedback: {},
  CloudStorage: {},
  BiometricManager: {
    isInited: false,
    isBiometricAvailable: false,
    biometricType: 'unknown',
    isAccessRequested: false,
    isAccessGranted: false,
    isBiometricTokenSaved: false,
    deviceId: '',
  },
};
