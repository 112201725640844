import Loading from './pages/Loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Carousel from './pages/Carousel';
import Profile from './pages/Profile';

import Drawer from './ui-kit/Draver';
import Social from './pages/Social';

import axios, { AxiosError } from 'axios';
import ErrorPAge from './pages/Error';
import { Data, TelegramUser } from './types';
import Notification from './ui-kit/Notification/Notification';
import { Text } from './ui-kit/Text/Text';
import { useMode } from './hooks/useMode';
import { mockWebApp } from './mocks/id';
import * as Sentry from '@sentry/react';
import Charging from './pages/Charging';
import NewRoadmap from './pages/NewRoadmap';
import NewInfo from './pages/NewInfo';

interface StartMiningResponse {
  energy_per_session: number;
  energy_per_tick: number;
  time_to_end: string;
  time_to_start: string;
}
interface MiningStatusResponse {
  end_time: string;
  energy: number;
  energy_per_session: number;
  energy_per_tick: number;
  finish: boolean;
  is_mining: boolean;
  mining_energy: number;
  start_time: string;
}

interface StopMiningResponse {
  energy: number;
}

function App() {
  const { mode } = useMode();
  const [isChargeCompleted, setIsChargeCompleted] = useState(false);
  const [maxValue, setMaxValue] = useState(8000);
  const [chargedValue, setChargedValue] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  const [avatar, setAvatar] = useState<string>('');
  const user: TelegramUser =
    mode === 'DEV'
      ? mockWebApp.initDataUnsafe.user
      : window.Telegram.WebApp.initDataUnsafe.user;
  const webApp = mode === 'DEV' ? mockWebApp : window.Telegram.WebApp;
  const [error, setError] = useState<string | null>(null);

  const name = user?.first_name
    ? `${user?.first_name} ${user?.last_name}`
    : user?.username
    ? `@${user?.username}`
    : 'Тест';

  const [data, setData] = useState<Data | null>(null);

  const [showLoading, setShowLoading] = useState(true);
  const [showCarousel, setShowCarousel] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isOpenSocial, setIsOpenSocial] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenRoadmap, setIsOpenRoadmap] = useState(false);
  const [isOpenCharging, setIsOpenCharging] = useState(false);
  const [timeToStart, setTimeToStart] = useState<string | null>(null);
  const [timeToEnd, setTimeToEnd] = useState<string | null>(null);

  const onCloseSocial = () => {
    setIsOpenSocial(false);
  };

  const onOpenInfo = () => {
    setIsOpenInfo(true);
  };
  const onCloseInfo = () => {
    setIsOpenInfo(false);
  };

  const onOpenCharging = () => {
    setIsOpenCharging(true);
  };

  const onCloseCharging = () => {
    setIsOpenCharging(false);
  };

  const onCloseRoadmap = () => {
    setIsOpenRoadmap(false);
  };

  const onOpenHome = () => {
    onCloseSocial();
    onCloseInfo();
    onCloseRoadmap();
    onCloseCharging();
  };

  const hasUserInfo = useMemo(() => Boolean(user?.id), [user]);
  const hasInitData = useMemo(() => Boolean(webApp?.initData), [webApp]);
  const hasAccessGetData = useMemo(
    () => hasUserInfo && hasInitData,
    [hasUserInfo, hasInitData]
  );

  const reportSentryError = (
    errorMessage: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorData?: Record<string, any>
  ) => {
    const error = new Error(errorMessage);

    if (errorData) {
      Sentry.setContext('error_context_data', errorData);
    }

    Sentry.captureException(error);
  };

  const getUserData = useCallback(async (userId: number, initData: string) => {
    try {
      const response = await axios.post(
        `https://web-app.emcd.io/emcd/bot/login/${userId}`,
        undefined,
        { headers: { Authorization: initData } }
      );
      setData(response.data);
      setShowCarousel(!response.data.Reg);
      setShowError(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      setShowError(true);
      setError('Lets try to reload the page');
      reportSentryError('Ошибка при получении данных пользователя', {
        userId: user?.id,
        error: axiosError,
        initData,
      });
    } finally {
      setShowLoading(false);
    }
  }, []);

  const getMiningStatus = useCallback(
    async (userId: number, initData: string) => {
      try {
        const response = await axios.get(
          `https://web-app.emcd.io/emcd/bot/mining/status/${userId}`,
          { headers: { Authorization: initData } }
        );

        const data: MiningStatusResponse = response.data;
        setIsCharging(data?.is_mining);
        setTimeToStart(data?.start_time);
        setTimeToEnd(data?.end_time);
        setIsChargeCompleted(data.finish);
        setMaxValue(data?.energy_per_session);
        setChargedValue(data?.energy);
      } catch (error) {
        reportSentryError('Ошибка при получении данных майнинга', {
          userId: user?.id,
          error,
          initData,
        });
      }
    },
    []
  );

  const startMining = useCallback(async (userId: number, initData: string) => {
    try {
      const response = await axios.get(
        `https://web-app.emcd.io/emcd/bot/mining/start/${userId}`,
        { headers: { Authorization: initData } }
      );
      const data: StartMiningResponse = response.data;
      setIsChargeCompleted(false);
      setIsCharging(true);
      setTimeToStart(data?.time_to_start);
      setTimeToEnd(data?.time_to_end);
      setMaxValue(data?.energy_per_session);
    } catch (error) {
      reportSentryError('Ошибка старте майнинга', {
        userId: user?.id,
        error,
        initData,
      });
    }
  }, []);

  const stopMining = useCallback(async (userId: number, initData: string) => {
    try {
      const response = await axios.get(
        `https://web-app.emcd.io/emcd/bot/mining/stop/${userId}`,
        { headers: { Authorization: initData } }
      );
      setIsChargeCompleted(false);
      const data: StopMiningResponse = response.data;
      setIsCharging(false);
      setTimeToStart(null);
      setTimeToEnd(null);
      console.log('data.energy', data.energy);
      setChargedValue(data.energy);
      setIsChargeCompleted(true);
    } catch (error) {
      reportSentryError('Ошибка стопе майнинга', {
        userId: user?.id,
        error,
        initData,
      });
    }
  }, []);

  const getProfilePhoto = useCallback(async (user_id: number) => {
    try {
      const response = await axios.get(
        `https://api.telegram.org/bot7434692609:AAGqk4NG8vlDwsaF6SO7s4IJRQ_yYo1traA/getUserProfilePhotos`,
        { params: { user_id, limit: 1 } }
      );

      const photos = response.data.result.photos;
      if (photos.length > 0) {
        const photoFileId = photos[0][0].file_id;
        const url = await getFileUrl(photoFileId);
        setAvatar(url || '');
      }
    } catch (error) {
      reportSentryError('Ошибка при получении фото пользователя', {
        userId: user?.id,
        error: error,
      });
    }
  }, []);

  const getFileUrl = async (fileId: string) => {
    try {
      const response = await axios.get(
        `https://api.telegram.org/bot7434692609:AAGqk4NG8vlDwsaF6SO7s4IJRQ_yYo1traA/getFile`,
        {
          params: {
            file_id: fileId,
          },
        }
      );
      const filePath = response.data.result.file_path;
      const fileUrl = `https://api.telegram.org/file/bot7434692609:AAGqk4NG8vlDwsaF6SO7s4IJRQ_yYo1traA/${filePath}`;
      setAvatar(fileUrl);
      return fileUrl;
    } catch (error) {
      reportSentryError('Ошибка при получении урла фото пользователя', {
        userId: user?.id,
        file_id: fileId,
      });
    }
  };

  const handleInvite = () => {
    const inviteLink = data?.RefUrl || '';
    const shareText =
      'Hello! Join EMCD Universe with Me now and get Your bonus for early adopters! Invite your friends as well to increase level!';
    const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(
      inviteLink
    )}&text=${encodeURIComponent(shareText)}`;
    window.Telegram.WebApp.openTelegramLink(fullUrl);
  };

  useEffect(() => {
    if (hasAccessGetData) {
      console.log('hasAccessGetData');
      getUserData(user?.id, webApp?.initData).then(() => {
        getMiningStatus(user?.id, webApp?.initData);
        getProfilePhoto(user?.id);
      });
    } else {
      setShowLoading(false);
      setShowCarousel(false);
      setShowError(true);
    }
  }, [hasAccessGetData]);

  useEffect(() => {
    if (
      showCarousel ||
      showLoading ||
      isOpenSocial ||
      isOpenInfo ||
      isOpenRoadmap ||
      showError
    ) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [
    showCarousel,
    showLoading,
    isOpenSocial,
    isOpenInfo,
    isOpenRoadmap,
    showError,
  ]);

  useEffect(() => {
    window.Telegram.WebApp.expand();
  }, []);

  const [showNotification, setShowNotification] = useState(false);
  const handleShowNotification = () => setShowNotification(true);
  const handleCloseNotification = () => setShowNotification(false);

  useEffect(() => {
    console.log('showNotification', showNotification);
  }, [showNotification]);

  return (
    <main className="wrapper">
      {showNotification && (
        <Notification
          message="Copied! Now share it!"
          type="success"
          duration={2000}
          onClose={handleCloseNotification}
        />
      )}
      <div className="full_screen_container">
        {!showLoading && !showCarousel && (
          <Profile
            photoUrl={avatar}
            name={name}
            data={data}
            onOpenInfo={onOpenInfo}
            onOpenRoadmap={() => setIsOpenRoadmap(true)}
            handleInvite={handleInvite}
            handleShowNotification={handleShowNotification}
            onOpenCharging={onOpenCharging}
            timeToStart={timeToStart}
            timeToEnd={timeToEnd}
            isCharging={isCharging}
            chargedValue={chargedValue}
            isChargeCompleted={isChargeCompleted}
          />
        )}
        <ErrorPAge
          isOpen={showError}
          message={
            hasAccessGetData ? (
              error
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Text>Go to Telegram Bot</Text>
                <a href="https://t.me/emcd_universe_bot">@emcd_universe_bot</a>
              </div>
            )
          }
          onReload={() => getUserData(user.id, webApp.initData)}
        />
        <Loading isOpen={showLoading} />
        <Carousel
          isOpen={showCarousel}
          onCancel={() => setShowCarousel(false)}
        />
        <Drawer isOpen={isOpenCharging} onClose={onCloseCharging}>
          <Charging
            onClose={onOpenHome}
            currentValue={chargedValue}
            timeToStart={timeToStart ?? ''}
            timeToEnd={timeToEnd ?? ''}
            isCharging={isCharging}
            startCharging={() => startMining(user.id, webApp.initData)}
            onCompleteCharging={() => stopMining(user.id, webApp.initData)}
            isChargeCompleted={isChargeCompleted}
            maxValue={maxValue}
          />
        </Drawer>
        <Drawer isOpen={isOpenSocial} onClose={onCloseSocial}>
          <Social onClose={onOpenHome} />
        </Drawer>
        <Drawer isOpen={isOpenInfo} onClose={onCloseInfo}>
          <NewInfo onClose={onOpenHome} />
        </Drawer>
        <Drawer isOpen={isOpenRoadmap} onClose={onCloseRoadmap}>
          <NewRoadmap onClose={onOpenHome} />
        </Drawer>
      </div>
    </main>
  );
}

export default App;
