import arrow from '../../assets/arrow_new.svg';
import plus from '../../assets/3d_plus.png';
import styles from './styles.module.scss';
import { levels } from '../../data/levels';
import errorSvg from '../../assets/error.svg';

const NewInfo = ({ onClose }: { onClose(): void }) => {
  return (
    <div className={styles.container}>
      <div className={styles.close_button_container}>
        <button className={styles.close_button} onClick={onClose}>
          <img src={arrow} onClick={onClose} />
        </button>
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.header_left}>
            <div className={`${styles.header_title} ${styles.heading1}`}>
              Invite your friends
            </div>
            <div className={`${styles.header_subtitle} ${styles.body1}`}>
              Invite your friends and increase your bonus when you start the
              game!
            </div>
          </div>
          <div className={styles.header_right}>
            <img src={plus} className={styles.header_image} />
          </div>
        </div>
        <div className={styles.info_levels}>
          <div className={styles.info_column}>
            <div className={styles.info_column_title}>Friends</div>
            <div className={styles.info_column_numbers}>
              {levels.map(({ friends, title }) => (
                <div key={title} className={styles.info_column_number}>
                  {friends}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.info_column}>
            <div className={styles.info_column_title}>Processor Level</div>
            <div>
              {levels.map(({ title }) => (
                <div key={title} className={styles.info_column_level}>
                  {title}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.info_danger_container}>
          <img src={errorSvg} height={30} className={styles.info_danger_icon} />
          <div className={styles.body1}>
            Only first and second level friends are counted. All invited friends
            will remain yours when the game starts.
          </div>
        </div>
        <div className={styles.info_bonus_container}>
          <div className={styles.heading1}>The early follower bonus</div>
          <div className={styles.body1}>
            The early follower bonus is a unique achievement that provides an
            advantage at the start. It cannot be obtained or improved after the
            start.
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInfo;
