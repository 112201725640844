import './styles.css';
import logo from '../../assets/logo.svg';
interface HeaderProps {
  photoUrl: string;
  name: string;
}
const getInitials = (name: string) => {
  return name.charAt(0).toUpperCase();
};

const getColorFromName = (name: string) => {
  const colors = [
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f39c12',
    '#e67e22',
    '#e74c3c',
    '#d35400',
    '#c0392b',
  ];
  const index = name.charCodeAt(0) % colors.length;
  return colors[index];
};

const Header = ({ photoUrl, name }: HeaderProps) => {
  const initials = getInitials(name);
  const bgColor = getColorFromName(name);
  return (
    <div className="header_container">
      <div className="header_user">
        {photoUrl ? (
          <img
            className="header_avatar"
            src={photoUrl}
            width={50}
            height={50}
          />
        ) : (
          <div
            style={{
              width: 50,
              height: 50,
              borderRadius: '50%',
              backgroundColor: bgColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#ffffff',
              fontSize: 50 / 2,
              fontWeight: 'bold',
            }}
          >
            {initials}
          </div>
        )}

        <div className="header_username">{name}</div>
      </div>
      <img src={logo} height={20} />
    </div>
  );
};

export default Header;
