import { useEffect, useState } from 'react';
import arrow from './img/arrow_new.svg';
import energy from './img/colored_energy.svg';
import styles from './styles.module.scss';
import Button from '../../ui-kit/Button';
import { Animation } from './Animation/Animation';

const Charging = ({
  onClose,
  currentValue,
  timeToStart,
  timeToEnd,
  isCharging,
  startCharging,
  onCompleteCharging,
  isChargeCompleted,
  maxValue,
}: {
  onClose(): void;
  currentValue: number;
  timeToStart: string;
  timeToEnd: string;
  isCharging: boolean;
  startCharging(): void;
  onCompleteCharging(): void;
  isChargeCompleted: boolean;
  maxValue: number;
}) => {
  const [justStartedCharging, setJustStartedCharging] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const [remainingTime, setRemainingTime] = useState('');
  const [minedEnergy, setMinedEnergy] = useState(0);
  const [changeBatteryClicked, setChangeBatteryClicked] = useState(false);

  const calculateRemainingTime = (end: Date) => {
    const now = new Date();
    const diffInMs = end.getTime() - now.getTime();
    if (diffInMs <= 0) return '0h 0m';
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    if (isCharging) {
      const start = new Date(timeToStart);
      const end = new Date(timeToEnd);
      const totalDuration = end.getTime() - start.getTime();

      if (totalDuration > 0) {
        const interval = setInterval(() => {
          const now = new Date();
          const elapsedTime = now.getTime() - start.getTime();
          const progress = (elapsedTime / totalDuration) * 100;
          const mined = Math.min(
            (elapsedTime / totalDuration) * maxValue,
            maxValue
          );

          setProgressWidth(Math.min(progress, 100));
          setMinedEnergy(Math.floor(mined));
          setRemainingTime(calculateRemainingTime(end));

          if (now.getTime() >= end.getTime()) {
            clearInterval(interval);
            onCompleteCharging();
          }
        }, 100);

        return () => clearInterval(interval);
      }
    } else if (isChargeCompleted) {
      // Charging completed, display full progress and max energy
      setProgressWidth(100);
      setMinedEnergy(maxValue);
      setRemainingTime('');
    } else {
      // Reset progress when not charging
      setProgressWidth(0);
      setMinedEnergy(0);
      setRemainingTime('');
    }
  }, [isCharging, isChargeCompleted, timeToStart, timeToEnd]);

  useEffect(() => {
    if (isCharging && timeToStart) {
      const startTime = new Date(timeToStart).getTime();
      const now = Date.now();
      const timeSinceStart = now - startTime;

      // Если прошло менее 5 секунд с момента начала майнинга, показываем анимацию вставки аккумулятора
      if (timeSinceStart < 5000) {
        setJustStartedCharging(true);
      } else {
        setJustStartedCharging(false);
      }
    }
  }, [isCharging, timeToStart]);

  const handleButtonClick = () => {
    if (!isCharging) {
      // Начинаем зарядку
      startCharging();
      setJustStartedCharging(true); // Устанавливаем флаг, что зарядка только началась
      if (isChargeCompleted) {
        // Если зарядка была завершена и мы нажали "Change battery"
        setChangeBatteryClicked(true);
      } else {
        // Если зарядка только начинается
        setChangeBatteryClicked(false);
      }
    }
  };
  const handleBatteryChangeComplete = () => {
    setChangeBatteryClicked(false);
  };
  const resetJustStartedCharging = () => {
    setJustStartedCharging(false);
  };

  return (
    <div className={styles.container}>
      <button className={styles.close_button} onClick={onClose}>
        <img src={arrow} onClick={onClose} />
      </button>
      <div className={styles.videoBackground}>
        <Animation
          isCharging={isCharging}
          isChargeCompleted={isChargeCompleted}
          changeBatteryClicked={changeBatteryClicked}
          onBatteryChangeComplete={handleBatteryChangeComplete}
          justStartedCharging={justStartedCharging}
          resetJustStartedCharging={resetJustStartedCharging}
        />
      </div>
      <div className={styles.current_value_container}>
        <img src={energy} />
        <div className={styles.current_value}>
          {currentValue.toLocaleString('ru-RU')}
        </div>
      </div>
      <div className={styles.bottom_container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {isCharging
              ? 'Charging...'
              : isChargeCompleted
              ? 'Charge completed'
              : ''}
          </div>
          <div className={styles.energyValue}>
            <img src={energy} width={22} />
            <div
              style={{
                // minWidth: '115px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <span className={styles.minedEnergy}>
                {minedEnergy > 0 ? minedEnergy : 0}&nbsp;
              </span>
              <span className={styles.maxEnergy}>/{maxValue}</span>
            </div>
          </div>
        </div>

        <div className={styles.progressbar}>
          <div
            className={styles.progress}
            style={{ width: `${progressWidth}%` }}
          />
        </div>
        <Button
          pattern={!isCharging ? 'green' : 'secondary'}
          onClick={handleButtonClick}
          disabled={isCharging && !isChargeCompleted}
          className={styles.button}
        >
          <span className={styles.button_timer}>
            {isCharging
              ? `Change battery ${remainingTime}`
              : isChargeCompleted
              ? 'Change battery'
              : 'Start charge energy'}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default Charging;
